const HeaterEnergySource = [
  {
    id: 1,
    name: 'Electricity'
  },
  {
    id: 2,
    name: 'District Steam'
  },
  {
    id: 3,
    name: 'Natural Gas'
  },
  {
    id: 4,
    name: 'Fuel Oil'
  },
  {
    id: 5,
    name: 'Propane'
  },
  {
    id: 6,
    name: 'District Hot Water'
  },
	{
		id: 7,
		name: 'Geothermal Hot Water'
	}
]

export default HeaterEnergySource
