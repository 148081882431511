const HeatingSystemType = [
  {
    id: 1,
    name: 'Warm Air Heating'
  },
  {
    id: 2,
    name: 'Destratification Heating'
  },
  {
    id: 3,
    name: 'Air Rotation Heating'
  },
  {
    id: 4,
    name: 'Radiant Heating'
  }
]

export default HeatingSystemType
