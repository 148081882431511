const TrackingType = [
  {
    id: 1,
    name: 'Fixed'
  },
  {
    id: 2,
    name: 'Vertical Axis Tracking'
  },
  {
    id: 3,
    name: 'Incline Axis Tracking'
  },
  {
    id: 4,
    name: 'Two-Axis Tracking'
  }
]

export default TrackingType
