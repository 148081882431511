const UseType = [
  { id: 10, name: 'Education' },
  { id: 11, name: 'Office' },
  { id: 12, name: 'Retail' },
  { id: 13, name: 'Hospitality' },
  { id: 14, name: 'Industrial' },
  { id: 15, name: 'Institution' },
  { id: 16, name: 'Mixed Use' },
  { id: 17, name: 'Other' }
]

export default UseType
