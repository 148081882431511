const CompressorType = [
  {
    id: 1,
    name: 'Centrifugal'
  },
  {
    id: 2,
    name: 'Reciprocating'
  },
  {
    id: 3,
    name: 'Scroll'
  },
  {
    id: 4,
    name: 'Screw Driven'
  },
  {
    id: 5,
    name: 'Rotary Vane'
  },
  {
    id: 6,
    name: 'Screw'
  }
]


export default CompressorType
