import React from 'react'
import PerformanceComparison2 from 'pages/building/comparision/components/PerformanceComparison2'

const ComparisonPerformanceComparison = () => {

  return (
    <PerformanceComparison2/>
  )
}
export default ComparisonPerformanceComparison

