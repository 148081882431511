const Orientation = [
  { id: 1, name: 'North' },
  { id: 2, name: 'North East' },
  { id: 3, name: 'East' },
  { id: 4, name: 'South East' },
  { id: 5, name: 'South' },
  { id: 6, name: 'South West' },
  { id: 7, name: 'West' },
  { id: 8, name: 'North West' },
]

export default Orientation