const CoolingSystemType = [
  {
    id: 1,
    name: 'Air-Cooled Chiller'
  },
  {
    id: 2,
    name: 'Water-Cooled Chiller'
  },
  // {
  //   id: 3,
  //   name: 'District Cooling System'
  // },
  {
    id: 4,
    name: 'Split Unit AC'
  },
  {
    id: 5,
    name: 'Absorption Chiller'
  }
]

export default CoolingSystemType
